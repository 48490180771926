.spacer {
    height: 2rem;
}
.aboutHero {
    background-image: url('../../assets/images/h11.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: #1e1f31;
    background-blend-mode: overlay;
    width: 100%;
    height: 425px;
}

.aboutIntro {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.aboutCta {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    background: #e6e6e6;
}

@media (max-width: 375px) {
    .aboutHero h2{
        font-size: 1rem;
    }
}

@media (max-width: 320px) {
    .aboutHero h2{
        font-size: .75rem;
        text-transform: uppercase;
    }
}