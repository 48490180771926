@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    background: #f8f8f8;
    font-family: "Montserrat", sans-serif;
    font-size: 0.875rem;
}
