.newsletter-main {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.img_news {
    background-image: url(../../assets/images/newsletter.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

@media (max-width: 425px) {
    .img_news {
        height: 200px;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        border-bottom-left-radius: 0;
        margin-bottom: .5rem;
    }
}